import React, { useEffect } from "react";
import { Provider } from "react-redux";
import Theme from "../src/Theme";
import "url-search-params-polyfill";
import { useStore } from "../src/reducers/store";
import { routeChangeListener } from "../src/actions/routerActions";
import Script from "next/script";
import { API_KEYS } from "../src/__constants__/apiKeys";
import "../src/global.css";

const App = ({ Component, pageProps, router }) => {
  const store = useStore(pageProps.initialReduxState);
  const { pathname, asPath } = router;

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    store.dispatch(routeChangeListener(asPath, pathname, null));
  }, [pathname]);

  return (
    <Provider store={store}>
      <Theme>
        <Script
          id="gtag"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
             
              gtag("consent", "default", {
                ad_storage: "denied",
                analytics_storage: "denied",
                wait_for_update: 600000
              });
              
              gtag('js', new Date());

              gtag('config', '${API_KEYS.GTAG_KEY}', {
                page_path: window.location.pathname,
              });`,
          }}
        />

        <Script
          id="google-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(i,s,o,g,r,a,m){
              i['GoogleAnalyticsObject'] = r;
              i[r] = i[r] || function() {
                  (i[r].q = i[r].q || []).push(arguments)
              };
              i[r].l = 1*new Date();
              a = s.createElement(o);
              a.setAttribute('type', 'text/plain')
              a.setAttribute('data-cookiescript', 'accepted')
              m = s.getElementsByTagName(o)[0];
              a.async = 1;
              a.src=g;
              m.parentNode.insertBefore(a, m);
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

          window.ga('create', '${API_KEYS.GOOGLE_ANALYTICS_KEY}', 'auto');
          `,
          }}
        />
        <Script
          async
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${API_KEYS.GTAG_KEY}`}
        />

        <Script
          async
          strategy="afterInteractive"
          src="//cdn.cookie-script.com/s/6ab23f23e10c9a8c165de9f68fe84ddb.js"
        />

        <Script
          id="linkedin-pixel-script"
          async
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window._linkedin_data_partner_ids =
            window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push('${API_KEYS.LINKEDIN_PIXEL_KEY}');
            (function() {
                var s = document.getElementsByTagName('script')[0];
                var b = document.createElement('script');
                b.type = 'text/plain';
                b.setAttribute('data-cookiescript', 'accepted');
                b.setAttribute('data-cookiecategory', 'targeting');
                b.async = true;
                b.src =
                    'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                s.parentNode.insertBefore(b, s);
            })();
        `,
          }}
        />

        <Script
            id="facebook-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                !function(f, b, e, v, n, t, s) {
                  if (f.fbq) return;
                  n = f.fbq = function() {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                  };
                  if (!f._fbq) f._fbq = n;
                  n.push = n;
                  n.loaded = !0;
                  n.version = '2.0';
                  n.queue = [];
                  t = b.createElement(e);
                  t.async = !0;
                  t.src = v;
                  s = b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t, s);
                }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
                
                fbq('consent', 'revoke');
                fbq('init', '${API_KEYS.FACEBOOK_PIXEL_KEY}');
                fbq('track', 'PageView');
              `,
            }}
        />
        <Component {...pageProps} />
      </Theme>
    </Provider>
  );
};

export default App;
