import { createReplaceVariable } from '../utils/utilities';

export const GOOGLE_MAPS_KEY = createReplaceVariable('NEXT_PUBLIC_GOOGLE_MAPS_KEY_REPLACEME');
export const GOOGLE_MAPS_MAP_ID = createReplaceVariable('NEXT_PUBLIC_GOOGLE_MAPS_MAP_ID_REPLACEME');

export const API_KEYS = {
    GOOGLE_MAPS_KEY,
    API_URL: createReplaceVariable('NEXT_PUBLIC_API_URL_REPLACEME'),
    CONSUMER_DUTY_REPORT_URL: createReplaceVariable('NEXT_PUBLIC_CONSUMER_DUTY_REPORT_URL_REPLACEME'),
    SPARTA_URL: createReplaceVariable('NEXT_PUBLIC_SPARTA_URL_REPLACEME'),
    ACCOUNTS_URL: createReplaceVariable('NEXT_PUBLIC_ACCOUNTS_URL_REPLACEME'),
    PROFESSIONAL_URL: createReplaceVariable('NEXT_PUBLIC_PROFESSIONAL_URL_REPLACEME'),
    GOOGLE_ANALYTICS_KEY: createReplaceVariable('NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY_REPLACEME'),
    LINKEDIN_PIXEL_KEY: createReplaceVariable('NEXT_PUBLIC_LINKEDIN_PIXEL_REPLACEME'),
    GOOGLE_TAG_MANAGER_KEY: createReplaceVariable('NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY_REPLACEME'),
    CONSUMER_TYPE: createReplaceVariable('NEXT_PUBLIC_CONSUMER_TYPE_REPLACEME'),
    API_URL_INTERNAL: createReplaceVariable('API_URL_REPLACEME_INTERNAL'),
    SPARTA_URL_INTERNAL: createReplaceVariable('SPARTA_URL_REPLACEME_INTERNAL'),
    PROFESSIONAL_URL_INTERNAL: createReplaceVariable('PROFESSIONAL_URL_REPLACEME_INTERNAL'),
    STATIC_REGENERATION_TIME: createReplaceVariable('STATIC_REGENERATION_TIME'),
    GTAG_KEY: createReplaceVariable('NEXT_PUBLIC_GTAG_KEY_REPLACEME'),
    FACEBOOK_PIXEL_KEY: createReplaceVariable('NEXT_PUBLIC_FACEBOOK_PIXEL_REPLACEME'),
};

export function getBaseURL (type){
    return (type === 'firm') ? API_KEYS.API_URL : API_KEYS.SPARTA_URL;
}
